<template>
  <div class="flex min-h-screen items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm">
      <div>
        <img
          class="mx-auto h-12 w-auto my-4"
          :src="`/assets/${env.VUE_APP_APPLICATION}/logo-rectangle.svg`"
          :alt="`Logo ${env.VUE_APP_TITLE}`"
        />
      </div>

      <!-- start: login Error -->
      <div
        v-if="loginError"
        class="rounded-md bg-red-50 p-4"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <i class="far fa-exclamation-triangle text-red-800"></i>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-red-800">{{ loginError }}</p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                @click="loginError = null"
                class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
              >
                <span class="sr-only">Fermer</span>
                <i class="far fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end: login error -->

      <form
        class="mt-6"
        action="#"
        method="POST"
        @submit.prevent="login"
      >
        <div class="relative -space-y-px rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"></div>
          <div>
            <label for="email-address" class="sr-only">
              Adresse e-mail
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              required
              v-model="email"
              class="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              placeholder="Adresse e-mail"
            />
          </div>
          <div>
            <label for="password" class="sr-only">
              Mot de passe
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              v-model="password"
              class="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              placeholder="Mot de passe"
            />
          </div>
        </div>

        <div class="flex items-center justify-end mb-5">
          <div class="text-sm leading-6">
            <router-link
              to="/forgot-password"
              class="font-semibold text-primary-600 hover:text-primary-500"
            >
              Mot de passe oublié ?
            </router-link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:text-red disabled:cursor-progress"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              <svg class="animate-spin h-5 w-5 inline-block mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Chargement...
            </span>
            <span v-else>
              Connexion
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      loginError: null,
      isLoading: false,
      env: {}
    }
  },
  computed: {
    ...mapGetters([
      'application'
    ])
  },
  created () {
    this.env = process.env
  },
  methods: {
    login () {
      const { email, password } = this
      this.loginError = null
      this.isLoading = true
      this.$store
        .dispatch('AUTH_LOGIN', { url: '/auth/login-admin', payload: { email, password } })
        .then(() => {
          this.$router.push('/app/dashboard')
        })
        .catch(
          (err) => {
            this.loginError = err.data.message
            this.isLoading = false
          }
        )
    }
  }
}
</script>
